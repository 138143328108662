import orderInfo from "../../components/order-info/index.vue"
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: "basket-confirm",
  data() {
    return {
      mainAddress: {}
    }
  },
  created() {
    this.resetData()
    setTimeout(()=>{
      if (this.user && this.user.addresses.data.length) {
        this.changeUserInfo(this.user.addresses.data[0])
      }
    },50)

    if(this.basket && this.basket.length){
        this.getProductsIds()
    }
    this.fetchUser()
  },
  watch: {
    changedBasket() {
      if(this.changedBasket) {
        this.$toasted.error(this.$t('some_products_is_changed'));
      }
    },
    'user.addresses.data'(newVal){
      if(newVal){
        this.changeUserInfo(this.user.addresses.data[0])
      }
    }
  },
  components: {
    orderInfo
  },
  computed: {
    ...mapGetters({
      user: `profile/user`,
      addressComplete: `order/addressComplete`,
      stepDelivery: `order/stepDelivery`,
      stepPay: `order/stepPay`,
      basket: `basket/basket`,
      deliveryLoader: `payment/deliveryLoader`,
      changedBasket: `basket/changedBasket`,
      userInfo: `order/userInfo`,
      deliveryInfo: `delivery/deliveryInfo`,
      cardInfo: `order/cardInfo`,
    }),
  },
  methods: {
    ...mapMutations({
      changePopupAddressesList: `basket/CHANGE_POPUP_ADDRESSES_LIST`,
      changeUserInfo: 'order/CHANGE_USER_INFO',
      resetUserInfo: 'order/RESET_USER_INFO',
      changeCardPopup: 'order/CHANGE_POPUP_CARD_PAY',
      changeCardListPopup: 'order/CHANGE_POPUP_CARD_LIST',
      changeAddressComplete: `order/CHANGE_ADDRESS_COMPLETE`,
      changeDeliveryList: `order/CHANGE_POPUP_DELIVERY`,

      changeStepPay: `order/CHANGE_STEP_PAY`,
      resetDeliveryInfo: 'delivery/RESET_DELIVERY_INFO',
      resetCardInfo: 'order/RESET_CARD_INFO',
      changeStepDelivery: `order/CHANGE_STEP_DELIVERY`,
    }),
    ...mapActions({
      fetchUser: `profile/FETCH_USER_DATA`,
      fetchDelivery: `payment/GET_DELIVERY`,
      fetchBasketProductsCurrencies: `basket/SEND_BASKET_PRODUCTS_IDS`
    }),
    resetData(){
      this.resetUserInfo()
      this.resetDeliveryInfo()
      this.resetCardInfo()
      this.changeStepPay(false)
      this.changeStepDelivery(false)
    },
    changeDelivery(){
      let obj = {}
      let products = []
      for (let i in this.basket) {
        products.push({id: this.basket[i].id, count: this.basket[i].select_count})
      }
      if(this.basket.length !== products.length){
        console.log('!=')
        products.pop()
      }
      obj.products = products
      obj.address_id = this.userInfo.id
      this.fetchDelivery(obj).then(() => {
        this.changeDeliveryList(true)
      }).catch(error => {
        console.log(error);
        if (error.response.status === 400) {
          this.$toasted.error(error.response.data.message);
        }
      });

    },
    getProductsIds() {
      const productsIDs = this.basket.map((item) => item.id)
      this.fetchBasketProductsCurrencies({products: productsIDs})
    }
  }
}