import {mapGetters} from "vuex";

export default {
  props:['item'],
  name: "order-item",
  computed:{
    ...mapGetters({
      currency:'setting/currentCurrency',
    })
  }
}