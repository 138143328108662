import orderItem from '../order-item/index.vue'
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: "order-info",
  components: {
    orderItem
  },
  computed: {
    ...mapGetters({
      currency:'setting/currentCurrency',
      finishOrderLoader:'order/finishOrderLoader',

      deliveryList: `payment/deliveryList`,

      basket: `basket/basket`,
      stepPay: `order/stepPay`,

      userInfo: `order/userInfo`,
      deliveryInfo: `delivery/deliveryInfo`,
      cardInfo: `order/cardInfo`,
    }),
    totalAmountOriginal() {
      return this.basket.reduce((accumulator, currentValue) => {
        return (accumulator + ((currentValue.sale.new_price?currentValue.sale.new_price:currentValue.original_price) * currentValue.select_count));
      }, 0);
    },
    totalAmountCurrency() {
      return this.basket.reduce((accumulator, currentValue) => {
        return accumulator + ((currentValue.sale.new_price?currentValue.sale.new_price:currentValue.price) * currentValue.select_count);
      }, 0);
    },
    totalWeight() {
      return this.basket.reduce((accumulator, currentWeight) => {
        return accumulator + (currentWeight.dimensions.weight * currentWeight.select_count);
      }, 0);
    }
  },
  methods: {
    ...mapMutations({
      resetBasket: 'basket/RESET_BASKET',

      changeStepPay: `order/CHANGE_STEP_PAY`,
      resetUserInfo: 'order/RESET_USER_INFO',
      resetDeliveryInfo: 'delivery/RESET_DELIVERY_INFO',
      resetCardInfo: 'order/RESET_CARD_INFO',
      changeStepDelivery: `order/CHANGE_STEP_DELIVERY`,

    }),
    ...mapActions({
      sendOrder: `order/FINISH_ORDER`
    }),
    resetData(){
      this.resetUserInfo()
      this.resetDeliveryInfo()
      this.resetCardInfo()
      this.changeStepPay(false)
      this.changeStepDelivery(false)
    },
    submit() {
      console.log(this.deliveryList)
      let obj = {}
      // let products = []
      this.changeStepPay(false)
      // for (let i in this.basket) {
      //   products.push({id: this.basket[i].id, count: this.basket[i].select_count})
      // }
      console.log(this.userInfo);
      obj.delivery_id = this.deliveryInfo.delivery.id
      obj.order_id = this.deliveryList.order_id
      obj.payment_id = this.cardInfo.card.id
      console.log(obj);
      this.sendOrder(obj).then(() => {
        setTimeout(() => {
          this.changeStepPay(true)
        }, 500)
        this.$toasted.success(this.$t('confirmOrderSuccess'));
        this.resetBasket()
        this.resetData()
        this.$router.push({name: 'success-payment'}).catch(() => {
          console.log()
        })
      }).catch(error => {
        console.log(error);
        if (error.response.status === 400) {
          this.$toasted.error(error.response.data.message);
        }
        setTimeout(() => {
          this.changeStepPay(true)
        }, 500)
      });
    }
  }
}